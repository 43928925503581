import { useStyletron } from "baseui";
import React, { useEffect, useState } from "react";
import { useStopwatch } from 'react-timer-hook';
import {initSignedCall} from "./clevertap-signedcall.module"

const href = window.location.href
let url = new URL(href)
let logo = url.searchParams.get('url')
let cuid = url.searchParams.get('cuid')
let hexcode = url.searchParams.get('hex')
let imgnaam = logo
let metadata = {
  type: 'image/jpeg'
};

if (!imgnaam) {
  imgnaam = 'Clevertap.jpeg'
}





const PhoneMockup = ({ children }) => {
  const [css] = useStyletron();

  var naam = window.location.href

  naam = naam.slice(naam.indexOf('?') + 1)

  var lastIndex = naam.lastIndexOf('&');


  var hexnaam = naam.slice(lastIndex + 1)

  return (
    <div className="device device-iphone-x" >
      <div style={{ background: '#' + hexcode }} className="device-frame">
        <div
          className={css({
            overflow: "hidden",
            borderRadius: "30px",
          })}
        >
          {children}
        </div>
      </div>
      <div className="device-stripe" />
      <div className="device-header" />
      <div className="device-sensors" />
      <div className="device-btns" />
      <div className="device-power" />
    </div>
  );
};

const CallPageSlider = ({ onerror, ...props }) => {
  let clevertap = window.clevertap
  const [css, theme] = useStyletron();
  const [mute, setMute] = useState(false)

  const callstatus = props.callStatus;
  const [PatchClient, setPatchClient] = useState(null)
  const {
    seconds,
    minutes,
    hours,
    start,
    pause,
    reset
  } = useStopwatch({ autoStart: false, format: '12-hour' });

  const formatTime = (time) => {
    return String(time).padStart(2, '0')
  }

  useEffect(() => {
    if(clevertap.getCleverTapID()){
      (() => initSignedCall({
        accountId: '61a52046f56a14cb19a1e9cc',
        apikey: '9dcced09dae16c5e3606c22346d92361b77efdb360425913850bea4f22d812dd',
        cuid: cuid,
        cb: {
          incoming_call: (context) => { props.setCallStatus('incoming'); props.setContext(context) },
          miss: () => { props.setCallStatus('missed'),console.log('Missed call') },
          isAnswered: (res) => { if (res) { props.setCallStatus('ongoing') } },
          onCancel: (res) => { if (res) { props.setCallStatus('cancel') } },
          hangup: () => { props.setCallStatus('callend'); setMute(false),pause(); reset() }
        },
        name: '',
        ringtone: '',
        clevertap : clevertap
  
      }).then(res => {
        window.pclient = res
        setPatchClient(res)
        props.setCallStatus('initiated')
      }).catch(err => {
        alert(err)
        onerror(true)
        console.log('error', err);
      }))()
    }else{
      let interval = null;
      interval = setInterval(() => {
        if(clevertap.getCleverTapID()){
          clearInterval(interval);
          (() => initSignedCall({
            accountId: '61a52046f56a14cb19a1e9cc',
            apikey: '9dcced09dae16c5e3606c22346d92361b77efdb360425913850bea4f22d812dd',
            cuid: cuid,
            cb: {
              incoming_call: (context) => { props.setCallStatus('incoming'); props.setContext(context) },
              miss: () => { props.setCallStatus('missed'),console.log('Missed call') },
              isAnswered: (res) => { if (res) { props.setCallStatus('ongoing') } },
              onCancel: (res) => { if (res) { props.setCallStatus('cancel') } },
              hangup: () => { props.setCallStatus('callend'); setMute(false),pause(); reset() }
            },
            name: '',
            ringtone: '',
            clevertap : clevertap
      
          }).then(res => {
            setPatchClient(res)
            props.setCallStatus('initiated')
          }).catch(err => {
            alert(err)
            onerror(true)
            console.log('error', err);
          }))()
        }
      },5000);
    }
    
  }, [])

  useEffect(() => {
    if (PatchClient) {
      PatchClient.toggleMuteUnmute()
    }
  }, [mute])


  const ans = () => {

    if (PatchClient) {
      PatchClient.answer()
      start()
    }

  }
  const decline = () => {
    props.setCallStatus('decline')
    if (PatchClient) {
      PatchClient.decline()
    }

  }
  const hangup = () => {
    props.setCallStatus('end')
    if (PatchClient) {
      PatchClient.hangup()
    }

  }
  const silent = () => {
    setMute(!mute)
  }

  const unmute = () => {
    if (mute) {
      return "unmute.svg"
    }
    else {
      return "mute.svg"
    }
  }



  return (
    <div
      className={css({
        display: "flex",
        justifyContent: "flex-end",
        position: "relative",
        overflow: "hidden",
        [theme.mediaQuery.maxMedium]: {
          marginRight: "-40px"
        },
        [theme.mediaQuery.maxSmall]: {
          marginRight: "0px",
          justifyContent: "center"
        }
      })}
    >
      <div>
        {callstatus === 'incoming' ?
          <PhoneMockup   >
            <div >
              <h2 className="title">Incoming call</h2>
              <div className="body">
                <img src={imgnaam} alt="" />

              </div>
              <div className="bodyPart">
                <p>{props.context}</p>
              </div>
              <div className="footer">
                <img onClick={ans} className="accept" src="receive.svg" alt="" />
                <img onClick={decline} className="decline" src="hangup.svg" alt="" />
              </div>
              <p className="poweredby">Powered by Signed Call</p>
            </div>
          </PhoneMockup> : callstatus === 'ongoing' ?
            <PhoneMockup   >
              <div>
                <h2 className="title">Ongoing call</h2>
                <div className="body">
                  <img src={imgnaam} alt="" />

                  <p className="time">
                    <span>{formatTime(hours)}:{formatTime(minutes)}:{formatTime(seconds)}</span>
                  </p>
                </div>
                <div className="bodyPart">
                  <p>{props.context}</p>
                </div>
                <div className="footerafter">
                  <img onClick={silent} className="mute" src={unmute()} alt="" />
                  <img className="speaker" src="speaker.svg" alt="" />
                  <img className="bluetooth" src="bluetooth call.svg" alt="" />
                  <img onClick={hangup} className="end" src="hangup.svg" alt="" />
                </div>
                <p className="poweredby">Powered by Signed Call</p>
              </div>
            </PhoneMockup> : callstatus === 'decline' ?
              <PhoneMockup   >
                <div className="bodyPart">
                  <p>Call Declined</p>
                </div>
                <p className="poweredby">Powered by Signed Call</p>
              </PhoneMockup> : callstatus === 'end' ?
                <PhoneMockup  >

                  <div className="bodyPart">
                    <p>Thankyou</p>

                  </div>
                  <p className="poweredby">Powered by Signed Call</p>
                </PhoneMockup> : callstatus === 'cancel' ?
                  <PhoneMockup  >
                    <div className="bodyPart">
                      <p>Call Cancelled</p>
                    </div>
                    <p className="poweredby">Powered by Signed Call</p>
                  </PhoneMockup > : callstatus === 'missed' ?
                  <PhoneMockup  >
                    <div className="bodyPart">
                      <p>Missed Call</p>
                    </div>
                    <p className="poweredby">Powered by Signed Call</p>
                  </PhoneMockup > :
                  callstatus === 'callend' ?
                    <PhoneMockup  >
                      <div className="bodyPart">
                        <p>Call Ended</p>
                      </div>
                      <p className="poweredby">Powered by Signed Call</p>
                    </PhoneMockup> :
                    <PhoneMockup  >
                      <h2 className="title">Welcome To Signed Call</h2>
                      <div className="bodyPart">
                        <p>Ready To Receive Call</p>
                      </div>
                      <p className="poweredby">Powered by Signed Call</p>
                    </PhoneMockup>
        }
      </div>
    </div>
  );
};

export default function Receiver(props) {
  const [css] = useStyletron();
  const [callStatus, setCallStatus] = useState('')
  const [context, setContext] = useState('Your Order Is Here')
  const [error, showError] = useState(false)

  return (
    <>
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%"
        })}
      >{
          error ? <>
            <h3>Unauthorized Login. This cuid is already logged in elsewhere</h3>
          </> :
            <CallPageSlider callStatus={callStatus} setCallStatus={setCallStatus} context={context} setContext={setContext} onerror={showError} />
        }
      </div>
    </>
  );
}
