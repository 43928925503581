import { a } from "@react-spring/web"
import { useEffect, useState } from "react"
import { ColorPicker, useColor } from "react-color-palette"
import "react-color-palette/lib/css/styles.css"
import styled from "styled-components"
import "styled-components/macro"
import { Link } from "wouter"
import { makeid } from "./index"
import {initSignedCall} from "./clevertap-signedcall.module"

import toast, { Toaster } from 'react-hot-toast';
// import { ToastContainer, toast } from 'react-toast'
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';





const Container = styled(a.div)`
  position: fixed;
  top: 0;
  left: -10;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  
`
// pointer-events: none;

const Jumbo = styled.div`
  white-space: pre;
  margin-bottom: 2.5rem;
  font-size: 7em;
  font-weight: 800;
  letter-spacing: -4px;
  @media only screen and (max-width : 620px){
    font-size: 3.2em;
  }
`

const NavRight = styled(a.div)`
  position: fixed;
  left:0px;
  width: 100%;
  height: 100%;
  diplay:flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  
  top: 75%;
  font-size: 1.75em;
  font-weight: bold;
  @media only screen and (max-width : 620px){
    display: flex;
    top: 200px;
    
    font-size: 1.2em;
  }
`




const Box = styled(a.div)`
  position: absolute;
  transform: translate3d(-50%, -42%, 0);
  will-change: opacity;
`

const Line = styled(a.div)`
  position: relative;
  width: 100%;
  will-change: transform;
  overflow: hidden;
  line-height: 1.5em;
  @media only screen and (max-width : 620px){
    line-height: 1.8em !important;
    overflow: visible;
  }
`

const Cover = styled(a.div)`
  position: absolute;
  will-change: background, transform;
  top: 0;
  left: 0;
  width: 137%;
  height: 120%;
`



function Nav({ location, seturl, sethexcode, receiver, url, hexcode, ...props }) {
  const [enable, setEnable] = useState(true)
  const [PatchClient, setPatchClient] = useState(null)
  const loc = location
  const [color, setColor] = useColor("hex", "#121212");
  const [showPicker, setShowPicker] = useState(false)
  const [skip, setSkip] = useState(true)
  const [context, setContext] = useState("")
  const [businesstype, setBusinessType] = useState('')
  var fooddeliverycolor = useColor("hex", "#B185A7")
  var hyperlocalcolor = useColor("hex", "#C3A29E")
  var ridehailingcolor = useColor("hex", "#5C80BC")
  var fintechcolor = useColor("hex", "#DFF8EB")
  let clevertap = window.clevertap


  const handleClick = () => {
    setShowPicker(!showPicker)
  }

  const ecommerce = () => {
    setBusinessType('ecommerce')
    seturl('ecommerce.png')
    setColor(hyperlocalcolor[0])
    sethexcode("#C3A29E")

  }

  const delivery = () => {
    setBusinessType('delivery')
    seturl('delivery.png')
    setColor(fooddeliverycolor[0])
    sethexcode("#B185A7")
  }

  const ridehailing = () => {
    setBusinessType('ridehailing')
    seturl('ridehailing.png')
    setColor(ridehailingcolor[0])
    sethexcode("#5C80BC")
  }

  const finTech = () => {
    setBusinessType('finTech')
    seturl('fintech.png')
    setColor(fintechcolor[0])
    sethexcode("#DFF8EB")
  }


  useEffect(() => {
    if(clevertap.getCleverTapID()){
      (() => initSignedCall({
        accountId: '61a52046f56a14cb19a1e9cc',
        apikey: '9dcced09dae16c5e3606c22346d92361b77efdb360425913850bea4f22d812dd',
        cuid: makeid(12),
        cb: {
          incoming_call: () => { console.log('incoming call') },
          miss: () => { console.log('Missed call') }
        },
        name: '',
        ringtone: '',
        clevertap : clevertap
  
      }).then(res => {
        setPatchClient(res)
      }).catch(err => {
        console.log('error', err);
      }))()
    }else{
      let interval = null;
      interval = setInterval(() => {
        if(clevertap.getCleverTapID()){
          clearInterval(interval);
            (() => initSignedCall({
              accountId: '61a52046f56a14cb19a1e9cc',
              apikey: '9dcced09dae16c5e3606c22346d92361b77efdb360425913850bea4f22d812dd',
              cuid: makeid(12),
              cb: {
                incoming_call: () => { console.log('incoming call') },
                miss: () => { console.log('Missed call') }
              },
              name: '',
              ringtone: '',
              clevertap : clevertap
        
            }).then(res => {
              setPatchClient(res)
            }).catch(err => {
              console.log('error', err);
            }))()
        }
      }, 5000);
      // return () => clearInterval(interval);
    }
    

  }, [])

  const call = () => {
    let callContext
    if (context) {
      callContext = context
    } else {
      callContext = "Your Order Is Here"
    }
    if (PatchClient) {
      (() => PatchClient.call(
        receiver, callContext, {
        pstn: false,
        webhook: '',
        autoFallback: true,
        cli: {
          cc: "91",
          phone: "8707616903",
        },
        var1: '',
        var2: '',
      }
      )
      .then((response) => {
        if (response) {
          console.log("Call response : ", response);
        }
      }).catch((error) => {if(error.message !== undefined){toast.error(error.message,
        {position: 'top-center',
        duration: 5000,
        style:{width:"1500px",height:"100px",background: 'rgb(245 251 239)',color: 'rgb(58 62 59)'}})}}))()
      // PatchClient.call(
      //   receiver, callContext, {
      //   pstn: false,
      //   webhook: '',
      //   autoFallback: true,
      //   cli: {
      //     cc: "91",
      //     phone: "8707616903",
      //   },
      //   var1: '',
      //   var2: '',
      // }
      // )
    }
  }

  useEffect(()=>{
    if(loc == '/delivery'){
      {delivery()}
    }
  },[loc])





  var component = null;
  var next = null;




  const radioOptions = () => {

    const defaultColor = () => {
      setSkip(true)
    }

    const updateColor = () => {
      setSkip(false)
    }


    const handleUrl = (e) => {
      // var urlIn = event.target.value
      if (!e.target.value) {
        seturl(e.target.value)
        setEnable(false)
        return
      }
      seturl(e.target.value)
      setEnable(true)
    }

    const handleColor = (val) => {
      setColor(val)
      sethexcode(val.hex)
    }
    var imgvalue = ''



    switch (loc) {
      case '/':
        return (<Link to='/knot' ><button className="startBtn"> Let's Start </button></Link>)
      case '/knot':
        return (
          <>
          <div className="productChoice">
            <Link to='/business' style={{ color: "white" }}>We’ve launched a product</Link>
            <Link to='/business' style={{ color: "white" }}>We don’t have a product yet</Link>
            </div>
          </>)
      case '/business':
        return (
          <>
          <div className="businessChoice">
            <Link onClick={ecommerce} to='/ecommerce' style={{ color: "#3A3E3B" }}>E-Commerce</Link>
            <Link onClick={delivery} to='/delivery' style={{ color: "#3A3E3B" }}>Delivery</Link>
            <Link onClick={ridehailing} to='/ride' style={{ color: "#3A3E3B" }} >Ride Hailing</Link>
            <Link onClick={finTech} to='/finTech' style={{ color: "#3A3E3B" }} >FinTech (BFSI)</Link>
            </div>
          </>)
      case '/ecommerce':
        return ( 
          <>
          <div className="roleChoice">
            <Link to='/personalized' style={{ color: "white" }} >Buyer</Link>
            <Link to='/personalized' style={{ color: "white" }} >Seller</Link>
            <Link to='/personalized' style={{ color: "white" }} >CC Agent</Link>
            <Link to='/personalized' style={{ color: "white" }} >Merchant</Link>
            </div>
          </>)
      case '/delivery':
        return (
          <>
          <div className="roleChoice">
            <Link to='/personalized' style={{ color: "white" }}>Delivery Boy</Link>
            <Link to='/personalized' style={{ color: "white" }}>End User</Link>
            <Link to='/personalized' style={{ color: "white" }}>CC Agent</Link>
            <Link to='/personalized'style={{ color: "white" }}>Merchant</Link>
            </div>
          </>)
      case '/ride':
        return (
          <>
          <div className="roleChoice">
            <Link to='/personalized' style={{ color: "white" }} >Drivers</Link>
            <Link to='/personalized' style={{ color: "white" }} >End User</Link>
            <Link to='/personalized' style={{ color: "white" }} >CC Agent</Link>
            <Link to='/personalized' style={{ color: "white" }} >Booked For</Link>
            </div>
          </>)
      case '/finTech':
        return (
          <>
          <div className="roleChoice">
            <Link to='/personalized' style={{ color: "white" }} >Bank Staff</Link>
            <Link to='/personalized' style={{ color: "white" }} >End User</Link>
            <Link to='/personalized' style={{ color: "white" }} >CC Agent</Link>
            <Link to='/personalized' style={{ color: "white" }} >Merchant</Link>
            </div>
          </>)
      case '/personalized':
        switch (businesstype) {
          case 'ecommerce':
            imgvalue = 'https://assets/images/ecommerce.png'
            break;
          case 'delivery':
            imgvalue = 'https://assets/images/delivery.png'
            break;
          case 'ridehailing':
            imgvalue = 'https://assets/images/ridehailing.png'
            break;
          case 'finTech':
            imgvalue = 'https://assets/images/fintech.png'
            break;
          default:
            imgvalue = '';

        }

        return (
          <>
            <div className="parentperson">
              <div className="personalNav">
                <div className="col-md-4 imgurl" >
                  <p>Url of your logo</p>
                  <input className="input" type="text" onChange={handleUrl} defaultValue={imgvalue} />
                  <span style={{fontSize : "0.38em"}}>e.g https://clevertap.com/wp-content/themes/clevertap2021/assets/images/ct-logo-2.svg</span>
                  <br />
                </div>
                <div className="col-md-4 context" >
                  <p>Set the context</p>
                  <input className="input" type="text" defaultValue="Your Order Is Here" maxLength={30} style={{ width: 300 }} onChange={(e) => setContext(e.target.value)} />
                  <span style={{ fontSize: '0.4em' }}>e.g Your Order Is Here</span>
                  <br />
                </div>
                <div className="col-md-4 hexchild" >
                  <p>Click to select color</p>
                  <div className="coverD">
                    <div
                      className="d-block"
                      onClick={handleClick}
                    >
                      <div className="colorbox" style={{ background: color.hex }} />
                    </div>
                    {showPicker ?
                      <div className="popover">
                        <ColorPicker width={400} height={150} color={color} onChange={handleColor} hideHSV hideRGB dark /> </div> : null}
                  </div>
                  <br />
                  {/* <Link onClick={updateColor} to='/enter'><button className="enter" disabled={!enable} >Enter</button></Link> */}
                </div>
              </div>
              <div className="buttonNav">
                <Link onClick={defaultColor} to='/enter'><button className="col-md-6 enter">Skip</button></Link>
                <Link onClick={updateColor} to='/enter'><button className="col-md-6 enter btn" disabled={!enable} >Enter</button></Link>
              </div>
            </div>
          </>
        )
      case '/skip':
      case '/enter':
        return (
          <>
          {PatchClient ? <h1 style={{ "cursor": "pointer" }} onClick={call}>Make Call</h1> : null}
          </>
        )
      default:
        component = "Default one"
        next = '/'

    }
  }

  return (
    <>
    <Toaster/>
    {/* <ToastContainer
    z-Index = {"5"}
    position ={"top-right"}
    /> */}
      <NavRight className={loc === '/enter' ? 'enterNav' : ''} {...props}>
        {radioOptions()}
      </NavRight>
    </>
  )
}

export { Container, Jumbo, Nav, Box, Line, Cover }
