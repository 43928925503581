import { useSpring, useTransition } from "@react-spring/core"
import { a } from "@react-spring/three"
import { Environment, Loader, Shadow, useGLTF, useTexture } from "@react-three/drei"
import { Canvas, extend, useFrame, useThree } from "@react-three/fiber"
import React, { Suspense, useLayoutEffect, useMemo, useRef, useState, useEffect } from "react"
import { MdContentCopy,MdShare } from "react-icons/md"
import * as THREE from "three"
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { Route, Switch, useLocation,Link } from "wouter"
import DistortionMaterial from "./DistortionMaterial"
import Receiver from "./Receiver"
import { Box, Container, Cover, Jumbo, Line, Nav } from "./Styles"

import { EmailShareButton,EmailIcon,WhatsappIcon,WhatsappShareButton} from "react-share";
import Breadcrumb from "./Breadcrumb";
export const NumberContext = React.createContext();
import toast, { Toaster } from 'react-hot-toast';
// import { ToastContainer, toast } from 'react-toast'

// extend({ OrbitControls });



const torus = new THREE.TorusBufferGeometry(4, 1.2, 128, 128)
const torusknot = new THREE.TorusKnotBufferGeometry(3, 0.8, 256, 16)
const material1 = new DistortionMaterial()
const material2 = new DistortionMaterial()
const material3 = new DistortionMaterial()

const jumbo = {
  "/": ["Welcome To", "Signed Call"],
  "/knot": ["Tell us", "where you’re at"],
  // "/bomb": ["What kind", "of business", "do you have?"],
  "/business": ["Tell us what", "Industry you’re in"],
  "/hyperLocal": ["Select Role"],
  "/ecommerce":["Tell us what you do"],
  "/fooddelivery": ["Select Role"],
  "/ride": ["Tell us what you do"],
  "/finTech": ["Tell us what you do"],
  "/delivery": ["Tell us what you do"],
  "/enter": ["Receiver's link :"],
  '/personalized': ["Get In-App", "Call experience"],
  // "/enduser": ["For Personalized", "Experience"],
  // "/ccagent": ["For Personalized", "Experience"],
  "/merchant": ["Get In-App", "Call experience"],
  '/deliveryBoy': ["Select Role"],
  '/restaurant': ["Select Role"],
  '/bookedFor': ["Select Role"],
  '/drivers': ["Select Role"],
  '/bankStaff': ["Select Role"],
}


function Shape({ geometry, material, args, textures, opacity, color, shadowScale = [9, 1.5, 1], ...props }) {
  const ref = useRef()
  const { mouse, clock } = useThree()
  const [ao, normal, height, roughness] = textures
  const [rEuler, rQuaternion] = useMemo(() => [new THREE.Euler(), new THREE.Quaternion()], [])

  useFrame(() => {
    if (ref.current) {
      rEuler.set((-mouse.y * Math.PI) / 10, (mouse.x * Math.PI) / 6, 0)
      ref.current.quaternion.slerp(rQuaternion.setFromEuler(rEuler), 0.1)
      ref.current.material.time = clock.getElapsedTime() * 3
    }
  })
  return (
    <group {...props}>
      <a.mesh
        ref={ref}
        args={args}
        geometry={geometry}
        material={material}
        material-color={color}
        material-aoMap={ao}
        material-normalMap={normal}
        material-displacementMap={height}
        material-roughnessMap={roughness}
        material-opacity={opacity}>
        <Shadow opacity={0.2} scale={shadowScale} position={[0, -8.5, 0]} />
      </a.mesh>
    </group>
  )
}

function Shapes({ transition }) {
  const { nodes } = useGLTF("/bomb-gp.glb")
  const textures = useTexture(["/ao.jpg", "/normal.jpg", "/height.png", "/roughness.jpg"])


  useLayoutEffect(() => {
    textures.forEach((texture) => ((texture.wrapT = texture.wrapS = THREE.RepeatWrapping), texture.repeat.set(4, 4)))
  }, [textures])
  return transition(({ opacity, ...props }, location) => (
    <a.group {...props}>
      <Switch location={location}>
        <Route path="/">
          <Shape geometry={torus} material={material1} textures={textures} color="black" opacity={opacity} />
        </Route>
        <Route path="/knot">
          <Shape geometry={torusknot} material={material2} textures={textures} color="white" opacity={opacity} />
        </Route>
        <Route path="/business">
          <Shape
            geometry={nodes.Little_Boy_Little_Boy_Material_0.geometry}
            material={material3}
            textures={textures}
            scale={[0.7, 0.7, 0.7]}
            rotation={[0, 0.5, 0]}
            shadowScale={[17, 2.5, 1]}
            color="black"
            opacity={opacity}
          />
        </Route>
      </Switch>
    </a.group>
  ))
}

function Text({ children, opacity, background, location }) {
  return (
    <>
      {location === "/personalized" ?
        <Box className="personalized-box" style={{ opacity }}>
          {React.Children.toArray(children).map((text, index) => (
            <Line key={index} style={{ transform: opacity.to((t) => `translate3d(0,${index * -50 + (1 - t) * ((1 + index) * 40)}px,0)`) }}>
              <div>{text}</div>
              <Cover style={{ background, transform: opacity.to((t) => `translate3d(0,${t * 100}%,0) rotateZ(-10deg)`) }} />
            </Line>
          ))}
        </Box>
        : location === "/enter" ? <Box style={{ opacity, top: "25%" }}>
          {React.Children.toArray(children).map((text, index) => (
            <Line key={index} style={{ transform: opacity.to((t) => `translate3d(0,${index * -50 + (1 - t) * ((1 + index) * 40)}px,0)`) }}>
              <div>{text}</div>
              <Cover style={{ background, transform: opacity.to((t) => `translate3d(0,${t * 100}%,0) rotateZ(-10deg)`) }} />
            </Line>
          ))}
        </Box> :
          <Box style={{ opacity }}>
            {React.Children.toArray(children).map((text, index) => (
              <Line key={index} style={{ transform: opacity.to((t) => `translate3d(0,${index * -50 + (1 - t) * ((1 + index) * 40)}px,0)`), lineHeight: '1.3em' }}>
                <div>{text}</div>
                <Cover style={{ background, transform: opacity.to((t) => `translate3d(0,${t * 100}%,0) rotateZ(-10deg)`) }} />
              </Line>
            ))}
          </Box>
      }
    </>
  )
}

const Separator = ({ children, ...props }) => (
  <span style={{ color: "blue" }} {...props}>
    {children}
  </span>
);

const options = {
  items: [
    { to: "/", label: "Home" },
    { to: "/knot", label: "Product" },
    { to: "/business", label: "Industry" },
    { to: "/delivery", label: "Role" },
    { to: "/personalized", label: "Personalization" },
    { to: "/enter", label: "Receiver's Link" }
  ]
};

export default function App({ receiver }) {
  let codeSnippet
  const [url, setUrl] = useState('');
  const [note, setNote] = useState('');
  const [hexcode, setHexcode] = useState('');
  const [share, setShare] = useState(false)


  let staging = window.location.href.split('/')
  staging.pop()
  staging = staging.join('/')
  codeSnippet = `${staging}/receiver?cuid=${receiver}&hex=${hexcode ? hexcode.replace('#', '') : null}&url=${url}`;
  const [location] = useLocation()

  const backgroundColorFunc = () =>{
    switch(location){
      case "/":
      case "/business":
      case "/personalized":
        return "#F5FBEF"
      case "/knot":
      case "/hyperLocal":
      case "/fooddelivery":
      case "/ride":
      case "/finTech":
      case "/enter":
        return "#272730"
    }
  }

  useEffect(() => {
    if (!hexcode && url == ''){
      setNote('(Note : Please go to previous page to get personalised receiver link)')
    }else{
      setNote('')
    }
  },[])

  const colorFunc = () =>{
    switch(location){
      case "/":
      case "/business":
      case "/personalized":
        return "#3A3E3B"
      case "/knot":
      case "/hyperLocal":
      case "/fooddelivery":
      case "/ride":
      case "/finTech":
      case "/enter":
        return "white"
    }
  }
  
 
  const props = useSpring({
    // background: location === "/" ? "white" : location === "/knot" ? "#272730" : "#ffcc6d",
    background: location === "/" ? "#F5FBEF" : location ==='/business'? "#F5FBEF" : location ==='/personalized'? "#F5FBEF": location === "/knot" ? "#272730" : location === "/ecommerce" ? "#272730" : location === "/delivery" ? "#272730" : location === "/finTech" ? "#272730" : location === "/enter" ? "#272730" : location === "/ride" ? "#272730" : "#ffcc6d",
    // color: location === "/" ? "black" : location === "/knot" ? "white" : "white",
    color : location === "/" ? "#3A3E3B" : location ==='/business'? "#3A3E3B" : location ==='/personalized'? "#3A3E3B": location === "/knot" ? "white" : location === "/hyperLocal" ? "white" : location === "/fooddelivery" ? "white" : location === "/finTech" ? "white" : location === "/enter" ? "white" : location === "/ride" ? "white" : "white"
  })
  // Animated shape props
  const transition = useTransition(location, {
    from: { position: [0, 0, -20], rotation: [0, Math.PI, 0], scale: [0, 0, 0], opacity: 0 },
    enter: { position: [0, 0, 0], rotation: [0, 0, 0], scale: [1, 1, 1], opacity: 1 },
    leave: { position: [0, 0, -10], rotation: [0, -Math.PI, 0], scale: [0, 0, 0], opacity: 0 },
    config: () => (n) => n === "opacity" && { friction: 60 },
  })

  const handleHexCode = (val) => {
    setHexcode(val)
  }

  const handleUrl = (val) => {
    setUrl(val)
  }

  

  const handleShare = () =>{
    setShare(!share)
  }

  const CopyComponent = ({ clickFn }) => {
    const [isCopied, setIsCopied] = useState(false)
    return (
      <span className="codespan">{isCopied ? "Copied!" : <MdContentCopy onClick={() => {
        clickFn();
        setIsCopied(true)
        setTimeout(() => {
          setIsCopied(false)
        }, 1500);

      }} cursor={'pointer'} />}</span>
    )
  }

  const onCopyText = async () => {
    await navigator.clipboard.writeText(codeSnippet)
  };

  const dynamicColor = () => {
    // console.log('check function call', location)
    switch(location){
      case "/":
      case "/business":
      case "/personalized":
        return {color:"#3A3E3B"}
      case "/knot":
      case "/hyperLocal":
      case "/delivery":
      case "/ride":
      case "/finTech":
      case "/enter":
      case "/ecommerce" :
        return {color:"white"}
    }
  }


  return (
    <>
    <div className="breadcrumb">
          <Breadcrumb location={location} separator={<Separator>/</Separator>}>
            {options.items.map(({ to, label }) => {
              // const Icon = options.icons[label];
              return (
                <div key={to} className="some-custom-classname" style={dynamicColor()}>
                  {/* {Icon && <Icon />} */}
                  <Link to={to}>{label}</Link>
                </div>
              );
            })}
          </Breadcrumb>
          </div>

      {location === "/receiver" ? <Receiver /> :
        <>
          <Container style={{ ...props }}>
            <Jumbo>
              {transition((style, location) =>
                <Text location={location} open={true} t={style.t} opacity={style.opacity} background={props.background} children={jumbo[location]} style={{ lineHeight: '1.3em' }} />
              )}
            </Jumbo>
            {location === "/enter" ?
              <>

                <div className="container_new">
                  <div className="code-snippet">
                    <div className="code-section">
                      <pre ><a  href={codeSnippet} target="_blank" >{codeSnippet}</a></pre>
                      <small className="linkContext">(This is a sharable link to receive a call. Request you to copy this link and share/open in a new window)</small>
                      <br/>
                      {hexcode ? (url ? '' : <small className="note">{note}</small>) : <small className="note" >{note}</small>}
                      
                    </div>
                    <CopyComponent clickFn={onCopyText} />
                    <MdShare onClick={handleShare} style={{overflow:'visible'}}/>
                    {share?
                    <>
                    <div className="overlay">
                      <div className="popup">
                        <h2>Share</h2>
                        <a className="close" onClick={()=>setShare(false)} href="#">&times;</a>
                        <div className="content"> 
                        <div className="icons">
                        <EmailShareButton 
                        url ={codeSnippet}
                        >
                        <EmailIcon  size={42} round={true} />
                        </EmailShareButton>

                        <WhatsappShareButton 
                        url ={codeSnippet}
                        >
                        <WhatsappIcon  size={42} round={true} />
                        </WhatsappShareButton>
                        </div>
                      <div className="copytext"><div className="codesnippet">{codeSnippet}</div> <div className="copybtn"><CopyComponent clickFn={onCopyText} /></div>  </div>
                        {/* <ShareSocial 
                          style={style_new}
                          url ={codeSnippet}
                          socialTypes={['facebook','twitter','reddit','linkedin']}
                          /> */}
                        </div>
                      </div>
                    </div>
                    </>
                    :null
                    }
                  </div>
                </div>
              </>
              : null}
          </Container>
          <Canvas concurrent camera={{ position: [0, 0, 20], fov: 50 }} onCreated={({ gl }) => (gl.toneMappingExposure = 1.5)}>
            <spotLight position={[0, 30, 40]} />
            <spotLight position={[-50, 30, 40]} />
            <Suspense fallback={null}>
              <Shapes transition={transition} />
              <Environment files="photo_studio_01_1k.hdr" />
            </Suspense>
          </Canvas>
          {location === "/personalized" ? <Nav className={location === "/personalized"? 'personalizedNav':location === "/enter"?'enterNav': ''} sethexcode={handleHexCode} seturl={handleUrl} url={url} hexcode={hexcode} location={location} style={{ color: props.color, top: '75%' }} /> :
            <Nav sethexcode={handleHexCode} seturl={handleUrl} url={url} hexcode={hexcode} location={location} style={{ color: props.color }} receiver={receiver} />}
          <Loader />
          
        </>}
    </>
  )
}
